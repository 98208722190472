<template>
  <div class="edit-product">
    <KTPortlet :title="`Chỉnh sửa dịch vụ: ${nameProduct}`" headSize="md">
      <template v-slot:body>
        <b-form @submit.prevent="onSubmit">
          <div class="row">
            <b-form-group label="Tên" class="col-7">
              <b-form-input
                type="text"
                required
                v-model="form.name"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Tên viết tắt" class="col-5">
              <b-form-input
                type="text"
                required
                v-model="form.shortName"
              ></b-form-input>
            </b-form-group>
          </div>

          <b-form-group label="Tiệm">
            <v-select :options="allOutlets" multiple label="name" v-model="outLetsSelected">
              <template v-slot:no-options="{ allOutlets }">
                <em v-if="!allOutlets">Không tìm thấy tiệm nào</em>
              </template>
            </v-select>
          </b-form-group>

          <b-form-group id="input-group-2" label="Giá" label-for="input-2">
            <b-form-input
              id="input-2"
              type="text"
              required
              v-model="form.price"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-4"
            label="Trạng thái"
            label-for="input-4"
          >
            <b-form-select
              id="input-4"
              v-model="form.status"
              :options="status"
              required
            ></b-form-select>
          </b-form-group>
        </b-form>
      </template>
    </KTPortlet>
    <b-button v-on:click="onSubmit()" block variant="primary"
      >Hoàn thành</b-button
    >
  </div>
</template>

<script>
import KTPortlet from "@/components/Portlet.vue";
import { Status } from "./../../constans";
import { updateSuccess } from "@/helper/index";
import { mapGetters } from "vuex";
import vSelect from "vue-select";

export default {
  name: "Edit",
  components: {
    KTPortlet,
    vSelect
  },
  data() {
    return {
      status: [
        {
          value: Status.Actived,
          text: "Kích hoạt"
        },
        {
          value: Status.NotActived,
          text: "Chưa kích hoạt"
        }
      ],
      form: {
        name: "",
        price: 0,
        outletId: [],
        position: 0,
        status: Status.NotActived,
        shortName: ""
      },
      nameProduct: "",
      outLetsSelected: []
    };
  },
  computed: mapGetters(["allOutlets"]),
  methods: {
    onSubmit() {
      this.form.outletId = []
      this.outLetsSelected.forEach(element => {
        this.form.outletId.push(element._id);
      });
      const data = JSON.parse(
        JSON.stringify({ ...this.form, ...{ id: this.form._id } })
      );

      if (!data.shortName) delete data.shortName;
      
      delete data._id;
      delete data.createdAt;
      delete data.updatedAt;
      delete data.__v;
      delete data.merchantId;

      this.$store.dispatch("updateProduct", data).then(() => {
        updateSuccess("product");
      });
    },
    getListOutlet() {
      this.$store.dispatch("setOutlets", { status: 1 }).then(() => {
        this.outLetsSelected = this.allOutlets.filter(outlet => {
          return this.form.outletId.find(id => id === outlet._id)
        }
      );
      });
    },
  },
  mounted() {
    this.getListOutlet();
    const item = this.$router.history.current.params?.item;
    this.form.outletId = []
    if (item) {
      this.form = { ...this.form, ...item };
      this.$router.replace({ params: { item: item._id } });
      this.nameProduct = item.name;
    }
  }
};
</script>
